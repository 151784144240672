console.log("bundle is connected")

import jQuery from "jquery"
window.$ = window.jQuery = jQuery

require("slick-carousel")

import PhotoSwipe from "photoswipe"
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default"
window.PhotoSwipe = PhotoSwipe
window.PhotoSwipeUI_Default = PhotoSwipeUI_Default

import select2 from "select2/dist/js/select2.full.js"
select2($)
window.select2 = select2

require("suggestions-jquery")

import swal from "sweetalert"
window.swal = swal
